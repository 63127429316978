<template lang="pug">
div.view_container
  Header
  AddIntroVideo(
    v-if="!isSafari && !isIOS"
    ref="add_intro_video"
    v-on:done="next"
  )
  AddIntroVideoWithFileUpload(
    v-if="isIOS"
    v-on:done="next"
  )
</template>

<script>
/* global gtag */
import { SEND_INVITE_MUTATION } from '../graphql/mutations'
import config from '../appConfig'
import AddIntroVideo from '../components/AddIntroVideo'
import AddIntroVideoWithFileUpload from '../components/AddIntroVideoWithFileUpload'
import Header from '../components/Header'

export default {
  name: 'AddIntroVideoContainer',
  props: {
  },
  components: {
    AddIntroVideo,
    AddIntroVideoWithFileUpload,
    Header,
  },
  computed: {

  },
  data () {
    return {
      isIOS: false,
      isSafari: false,
    }
  },
  apollo: {

  },
  methods: {
    invite: async function() {
      this.loading = true
      this.$apollo.mutate({
        mutation: SEND_INVITE_MUTATION,
        variables: {
          participantIds: this.participantsToInvite
        },
      }).then((data) => {
        // console.log(data)
        this.loading = false
        const success = data.data.sendInvite.success
        const errors = data.data.sendInvite.errors
        this.errorsToast(errors)

        if (success) {
          this.next()
        }
      }).catch((error) => {
        console.error(error)
        this.loading = false
        this.errorToast(error)
      })
    },
    cleanUpVideo: function () {
      if (this.$refs.add_intro_video) this.$refs.add_intro_video.allStop()
    },
    next: function () {
      this.$router.push("/thanks")
    },
  },
  beforeMount: function () {
    this.isSafari = config.isSafari
    this.isIOS = config.isIOS
  },
  beforeRouteLeave(to, from, next) {
    this.cleanUpVideo()
    next()
  },
  beforeUnmount: function() {
    this.cleanUpVideo()
  },
  mounted: function() {
    // window.vue = this
    if (this.isSafari && !this.isIOS) {
      this.$toast.warning(
        "We currently do not support Mac Safari, Please use chrome:  https://www.google.com/chrome",
        {
          timeout: false,
          onClose: () => window.location.href = "https://www.google.com/chrome"
      })
    }

    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/add_intro_video'})
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {

}
</style>
